<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="receiptReportList.data && receiptReportList.data.length > 0 && showHeaders.length > 0 ? '370' : ''"
              :style="{maxHeight: !receiptReportList.length ? '900px' : ''}"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="showHeaders"
              :items="receiptReportList.data"
              style="word-break: break-word"
              :search="search"
              item-class="py-4"
              item-key="randomKey"
              fixed-header
              :loading="isProgressCircular"
              :items-per-page="limitData"
              :page.sync="page"
              @pagination="newPagination"
              @dblclick:row="doubleClick"
              @page-count="pageCount = $event"
              must-sort
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                </v-overlay>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <span :ref="`row-${item.id}`">{{ item.id }}</span>
              </template>
              <template v-slot:[`item.grand_total`]="{ item }">
                <span>{{numberWithCommas(item.grand_total)}}</span>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <span>{{numberWithCommas(item.total)}}</span>
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">
                    {{ $t("receipt.filters") }}
                  </h6>
                  <v-spacer />
                  <HtmlToPdf class="me-2 mb-1" :disabled="receiptReportList.data" :model="receiptReportList" :payload="payload" type="ReceiptReportPDF"/>
                  <ExcelAndResetFilterCommon :data="receiptReportList.data" @downloadExcelFile="downloadExcelFile" @resetFilters="resetFilters"/>
                </v-card-title>
                <v-divider />
                <v-form v-model="valid" ref="form">
                <v-card-text>
                  <v-row :dense="true">
                    <v-col cols="3">
                      <v-autocomplete
                          v-if="!type"
                          hide-details="auto"
                          v-model="selectedStatus"
                          :items="statusList"
                          item-text="name"
                          item-value="id"
                          flat
                          return-object
                          single-line
                          :label="$t('receipt.field.status')"
                      />
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-if="!type"
                            hide-details="auto"
                            v-model="selectedCustomer"
                            :items="customerList.data"
                            item-text="name_1"
                            item-value="id"
                            clearable
                            flat
                            return-object
                            single-line
                            :search-input="customerSearch"
                            :label="$t('receipt.field.customer')"
                        >
                            <template v-slot:append-item>
                                <div v-show="hasNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                                    <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    />
                                </div>
                            </template>
                        </v-autocomplete>
                        <v-text-field
                            v-else
                            readonly
                            :dense="true"
                            hide-details="auto"
                            v-model="selectCustomerForDialog.name_1"
                            :label="$t('receipt.field.customer')"
                            single-line
                        />
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                          hide-details="auto"
                          v-model="selectedCustomerGroup"
                          :items="customerGroupValue"
                          item-text="description"
                          item-value="id"
                          clearable
                          flat
                          return-object
                          :label="$t('receipt.customerGroup')"
                        />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        hide-details="auto"
                        clearable
                        v-model="selectedPaymentType"
                        :items="paymentGroupValue"
                        :label="$t('receipt.paymentType')"
                        item-text="description"
                        item-value="id"
                        return-object
                        flat
                        single-line
                        :menu-props="{ bottom: true, offsetY: true }"
                      />
                    </v-col>
                  </v-row>
                  <v-row :dense="true">
                    <v-col cols="3">
                      <v-autocomplete
                        hide-details="auto"
                        clearable
                        :disabled="selectedPaymentType.description !== 'Bank transfer'"
                        v-model="selectedBank"
                        :items="bankList"
                        item-text="name"
                        item-value="id"
                        :label="$t('receipt.bank')"
                        flat
                        return-object
                        hide-no-data
                        single-line
                        transition="scale-transition"
                        offset-y
                      />
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            v-if="!type"
                            hide-details="auto"
                            v-model="selectedEmployee"
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            clearable
                            flat
                            return-object
                            single-line
                            :search-input.sync="employeeSearch"
                            :label="$t('receipt.field.agent')"
                        >
                            <template v-slot:append-item>
                                <div v-show="hasEmployeeNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                                    <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    />
                                </div>
                            </template>
                        </v-autocomplete>
                        <v-text-field
                            v-else
                            readonly
                            :dense="true"
                            hide-details="auto"
                            v-model="selectEmployeeForDialog.name"
                            :label="$t('receipt.field.agent')"
                            single-line
                        />
                    </v-col>
                    <v-col cols="6" class="text-end d-flex">
                        <v-menu
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="receipt_date"
                            transition="scale-transition"
                            offset-y
                            :nudge-left="40"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    hide-details="auto"
                                    class="me-2"
                                    single-line
                                    v-model="formattedFromDate"
                                    :label="$t('receipt.from')"
                                    @click:clear="receipt_date=''"
                                    clearable
                                    readonly
                                    :rules="profileById.date_format ? dateValid : dateValid2"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="receipt_date"
                                @change="$refs.startDateMenu.save(receipt_date)"
                            >
                              <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-menu
                            ref="endDateMenu"
                            v-model="endDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="receipt_validity"
                            transition="scale-transition"
                            offset-y
                            :nudge-left="160"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    hide-details="auto"
                                    single-line
                                    v-model="formattedToDate"
                                    :label="$t('receipt.to')"
                                    @click:clear="receipt_validity=''"
                                    clearable
                                    readonly
                                    :rules="profileById.date_format ? dateValid : dateValid2"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                :min="receipt_date ? receipt_date : ''"
                                v-model="receipt_validity"
                                @change="$refs.endDateMenu.save(receipt_validity)"
                            >
                              <v-btn small class="primary" @click="$refs.endDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="text-center mt-3">
                      <ExecuteAndRestCommon :valid="valid" @reset="reset" @paginate="paginate(false)" />
                      <v-dialog
                        dense
                        v-model="dialog"
                        scrollable
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            color="primary"
                            class="float-end"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left> mdi-view-comfy </v-icon>
                            {{ $t("receipt.fieldView") }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-row class="align-center ma-0">
                            <v-col>
                              <v-card-title class="primary--text">
                                <v-icon left color="primary">mdi-land-fields</v-icon>
                                  <h6 class="font-weight-regular">
                                    {{ $t("receipt.selectFields") }}
                                  </h6>
                              </v-card-title>
                            </v-col>
                            <v-col>
                              <v-btn x-small color="primary" class="float-end mx-3" @click="updateHeader">{{ $t('receipt.update') }}</v-btn>
                            </v-col>
                          </v-row>
                          <v-divider />
                          <v-card-text style="height: 300px;">
                            <v-select
                              dense
                              class="mt-2"
                              hide-details="auto"
                              v-model="selectedHeaders"
                              :items="headers"
                              item-text="text"
                              item-value="id"
                              return-object
                              clearable
                              multiple
                              outlined
                              :menu-props="{ bottom: true, offsetY: true, maxHeight: 200, overflowY: true }"
                              :label="$t('receipt.selectFields')"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item
                                  @mousedown.prevent
                                  @click="toggle"
                                >
                                  <v-list-item-action>
                                    <v-checkbox dense v-model="selectFields" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{$t('receipt.selectAll')}}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2" />
                              </template>
                            </v-select>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
                </v-form>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="9" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">
                        {{ $t("receipt.debitReport") }}
                      </h6>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        :autofocus="type ? true : false"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:[`item.receipt_date`]="props">
                <span>{{ props.item.receipt_date && (profileById.date_format ? formatDateDDMMYYYY(new Date(props.item.receipt_date).toISOString().substring(0,10)) :new Date(props.item.receipt_date).toISOString().substring(0,10)) }}</span>
              </template>
              <template v-slot:[`item.status`]="props">
                {{ statusList.filter(x => x.id == props.item.status)[0].name }}
              </template>
              <template v-slot:[`footer`]>
                <v-row>
                  <v-col cols="12">
                    <span class="text-subtitle-2">{{$t('receipt.totalPerReport')}}: 
                    {{receiptReportList && receiptReportList.totalPerReport ? numberWithCommas(receiptReportList.totalPerReport) : 0}}
                    </span>
                    <span class="text-subtitle-2 mx-16">{{$t('receipt.totalPerPaymentType')}}: 
                    {{receiptReportList && receiptReportList.totalPerPaymentType ? numberWithCommas(receiptReportList.totalPerPaymentType) : 0}}
                    </span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{ receiptReportList.data && receiptReportList.data.length && showHeaders.length > 0 ? numberOfRows : 0 }}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="m-0">
                    <v-container>
                      <v-pagination :total-visible="5" v-model="page" :length="receiptReportList.data ? pageCount : 0"></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">{{ $t("common.noDataFound") }}</v-card-text>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import exportFromJSON from 'export-from-json';
import { getAuxiliaryZtableValueByName } from "@/utils";
import {formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate} from '@/utils';
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
    name: "DeliveryReport",
    props: ['type', 'selectCustomerForDialog', 'selectEmployeeForDialog'],
    components: {
        ListSkeleton,
        HtmlToPdf,
        ExcelAndResetFilterCommon,
        ExecuteAndRestCommon
    },
    mixins: [validationMixin, intersectionMixin, searchMixin],
    data() {
        return {
            listSkeleton: false,
            dialog: false,
            selectedHeaders: [],
            selectFields: false,
            search: this.$route.query.search || "",
            limitData: this.limit,
            limit: this.pageChange?.limit ? Number(this.pageChange?.limit) : 10,
            page: this.pageChange?.page ? Number(this.pageChange?.page) : 1,
            pagination: null,
            selectedStatus: {
                id: this.$route.query.status === 'all' ? null : this.$route.query.status === 'closed' ? 1 : this.$route.query.status === 'cancelled' ? 2 : this.$route.query.status === 'open' && 0 || null
            },
            startDateMenu: false,
            endDateMenu: false,
            receipt_date: this.$route.query.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0,10),
            receipt_validity: this.$route.query.endDate || new Date().toISOString().substring(0,10),
            selectedCustomer: {
                id: Number(this.$route.query.custId) || null
            },
            selectedEmployee: {
                id: Number(this.$route.query.empId) || null
            },
            customerPage: 1,
            customerLimit: 10,
            employeePage: 1,
            employeeLimit: 10,
            employeeSearch: '',
            selectedCustomerGroup: null,
            customerGroupValue: [],
            selectedBank: {
                name: this.$route.query.bank || null
            },
            selectedPaymentType: {
                id: Number(this.$route.query.type) || null
            },
            paymentGroupValue: [],
            valid: false,
            numberOfRows: "",
            custom: {id: 111111111111111, description: this.$t('invoice.empty')},
            isProgressCircular: false,
        };
    },
    computed: {
        params() {
            return {
                search: this.search,
                customer_id: this.selectedCustomer?.id,
                employee_id: this.selectedEmployee?.id,
                selectedStatus: this.selectedStatus?.id,
                receipt_date: this.receipt_date,
                receipt_validity: this.receipt_validity,
                customer_group_id: this.selectedCustomerGroup?.id,
                selectedBank: this.selectedBank?.name,
                selectedPaymentType: this.selectedPaymentType?.id,
            };
        },
        payload() {
          return {
            customer_id: this.selectedCustomer?.name_1,
            employee_id: this.selectedEmployee?.name,
            selectedStatus: this.selectedStatus?.name,
            receipt_date: this.receipt_date,
            receipt_validity: this.receipt_validity,
            customer_group_id: this.selectedCustomerGroup?.description,
            selectedBank: this.selectedBank?.name,
            selectedPaymentType: this.selectedPaymentType?.description,
          }
        },
        selectAllHeaders() {
            return this.selectedHeaders.length === this.headers.length
        },
        ...mapGetters({
            receiptReportList: "receipt/receiptReportList",
            locale: "locale",
            showHeaders: "receiptReport/showHeaders",
            accessRight: 'accessRight',
            pageChange: "receiptReport/pageChange",
            profileById: 'profile/profileById',
            customerList: 'customer/customerList',
            employeeList: 'employee/employeeList',
            bankList: 'bank/bankList',
            rowIndex: "receipt/rowIndex",
        }),
        statusList() {
            return [
                { name: this.$t('receipt.field.all'), id: null },
                { name: this.$t('receipt.field.open'), id: 0 },
                { name: this.$t('receipt.field.finished'), id: 1 },
                { name: this.$t('receipt.field.canceled'), id: 2 }
            ]
        },
        formattedFromDate: {
          get() {
            return this.profileById.date_format ? this.formatDateDDMMYYYY(this.receipt_date) : parseDateYYYYMMDD(this.receipt_date)
          },
          set(val) {
            if (val.length == 10) {
              this.receipt_date = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
            }
          }
        },
        formattedToDate: {
          get() {
            return this.profileById.date_format ? this.formatDateDDMMYYYY(this.receipt_validity) : parseDateYYYYMMDD(this.receipt_validity)
          },
          set(val) {
            if (val.length == 10) {
              this.receipt_validity = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
            }
          }
        },
        headers() {
            return [
                { text: this.$t("documentSeries.docNumber"), value: "recipt_document_number", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("documentSeries.series"), value: "document_number_series", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.customerName"), value: "customer_name", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.email"), value: "email", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.address"), value: "address", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.town"), value: "town", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.country"), value: "country", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.agent"), value: "employee.name", width: "140px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.authorizedDealerNo"), value: "authorized_dealer_no", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.receiptStatus"), value: "receiptStatus.description", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.cel"), value: "cellular", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.customerId"), value: "customer.id", width: "100px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.employeeId"), value: "employee_id", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.fax"), value: "fax", width: "140px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.tel1"), value: "phone_1", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.tel2"), value: "phone_2", width: "130px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.accountUpdate"), value: "accounting_update", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.receiptDate"), value: "receipt_date", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.remarks"), value: "remarks", width: "140px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.status"), value: "status", width: "100px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.receiptYear"), value: "receipt_year", width: "100px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.withHoldingTax"), value: "with_holding_tax", width: "160px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.total"), value: "total", width: "120px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.grandTotal"), value: "grand_total", width: "130px", align: "start", class: "px-1", sortable: true },     
                { text: this.$t("receipt.field.createdAt"), value: "created_at", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: this.$t("receipt.field.updatedAt"), value: "updated_at", width: "180px", align: "start", class: "px-1", sortable: true },
                { text: "randomKey", value: Math.random().toString(36).slice(2), align: ' d-none' }
            ]
        },
        empty() {
          return this.$t('invoice.empty')
        }
    },
    created() {
        if (this.showHeaders.length == 0) {
          this.$store.commit('receiptReport/SHOW_HEADERS', this.headers)
        }
        this.page = this.pageChange.page;
        this.limit = this.pageChange.limit;
        this.limitData = this.limit;
    },
    beforeDestroy() {
        this.$store.commit("receiptReport/SHOW_PAGE_CHANGE", {page: this.pagination.page, limit: this.pagination.itemsPerPage})
    },
    destroyed() {
      if (!this.$route.params.receipt_id) {
        this.$store.commit('receipt/SET_RECEIPT_REPORT', [])
      }
    },
    watch: {
        empty() {
          this.custom.description = this.empty
        },
        pagination: {
            handler(newVal) {
                if (this.pagination) {
                this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10
                }
            },
            deep: true
        },
        selectCustomerForDialog() {
            this.selectedCustomer = this.selectCustomerForDialog;
        },
        selectEmployeeForDialog() {
          this.selectedEmployee = this.selectEmployeeForDialog;
        },
        type: {
            handler() {
                this.search = '';
                this.$emit('update:selectCustomerForDialog', null)
                this.$emit('update:selectEmployeeForDialog', null)
            }
        },
        customerLimit() {
            this.$store.dispatch('customer/GetCustomer', {page: 1, limit: this.customerLimit, order_by: 'name_1|asc'});
        },
        employeeLimit() {
            this.$store.dispatch('employee/GetEmployee', {page: 1, limit: this.employeeLimit, order_by: 'name|asc'});
        },
        employeeSearch(val) {
            this.$store.dispatch('employee/GetEmployee', {page: 1, limit: 10, where_like: val ? 'name|'+val : val, order_by: 'name|asc'});
        },
        '$route.query': {
            handler(newVal, oldVal) {
                if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length) 
                this.search = this.$route.query.search || ""

                this.receipt_date = this.$route.query.startDate || new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().substring(0,10)

                this.receipt_validity = this.$route.query.endDate || new Date().toISOString().substring(0,10)
                
                this.selectedCustomer = {
                    id: Number(this.$route.query.custId) || null
                }
                this.selectedEmployee = {
                  id: Number(this.$route.query.empId) || null
                }
                this.selectedBank = {
                  name: this.$route.query.bank || null
                }
                this.selectedPaymentType = {
                  id: Number(this.$route.query.type) || null
                }
                this.selectedStatus = {
                    id: this.$route.query.status === 'all' ? null : this.$route.query.status === 'closed' ? 1 : this.$route.query.status === 'cancelled' ? 2 : this.$route.query.status === 'open' && 0
                }
            }
        },
        selectedHeaders() {
            if (this.selectedHeaders.length === this.headers.length) {
                this.selectFields = true
            } else {
                this.selectFields = false
            }
        },
        locale() {
            this.updateHeader();
        }
    },
    async mounted() {
        this.selectedHeaders = this.showHeaders
        this.page = this.pageChange.page;
        this.limit = this.pageChange.limit;
        this.limitData = this.limit;
        // this.$store.dispatch("receipt/GetReceipt");
        this.$store.dispatch('bank/GetBank', {order_by: 'name|asc'});
        this.$store.dispatch('customer/GetCustomer', {page: this.customerPage, limit: this.customerLimit, order_by: 'name_1|asc'});
        this.$store.dispatch('employee/GetEmployee', {page: this.employeePage, limit: this.employeeLimit, order_by: 'name|asc'});
        getAuxiliaryZtableValueByName(2).then((tableCustomerValue) => {
          if (Object.keys(tableCustomerValue).length > 0) {
            this.customerGroupValue = tableCustomerValue.ztables.sort((a, b) =>
              a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
                ? 1
                : -1
            );
            this.customerGroupValue = [this.custom, ...this.customerGroupValue]
          }
        });
        let tableNameValue =  await getAuxiliaryZtableValueByName(8);
        if(Object.keys(tableNameValue).length > 0) {
            this.paymentGroupValue = tableNameValue.ztables.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 : -1);
            this.paymentGroupValue = [this.custom, ...this.paymentGroupValue]
        }
        let rowIndex = this.$store.state.receipt.rowIndex;
        if (rowIndex && this.receiptReportList?.data?.length > 0) {
          this.$refs[`row-${rowIndex}`].scrollIntoView({ block: "center" })
        }
        this.listSkeleton = false;
    },
    methods: {
        parseDateYYYYMMDD: parseDateYYYYMMDD,
        formatDateDDMMYYYY: formatDateDDMMYYYY,
        todayDate: todayDate,
        doubleClick(event, {item}) {
            const path = 'receipt/editReceipt/' + item.id;
            this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
            this.$store.commit('receipt/SET_ROWINDEX', item.id);
        },
        newPagination(val) {
            this.pagination = val
            this.numberOfRows = val.itemsLength
        },
        updateHeader() {
            this.$store.commit('receiptReport/SHOW_HEADERS', this.headers.filter(s => this.selectedHeaders.some(d => d.value === s.value)))
            this.selectedHeaders = this.showHeaders
            this.dialog = false;
        },
        toggle() {
            this.$nextTick(() => {
                if (this.selectAllHeaders) {
                this.selectedHeaders = []
                } else {
                this.selectedHeaders = this.headers.slice()
                }
            })
        },
        async infiniteScroll([{ isIntersecting, target }]) {
            if (isIntersecting) {
                const ul = target.offsetParent;
                const scrollTop = target.offsetParent.scrollTop;
                this.customerLimit += 10;
                this.employeeLimit += 10;
                await this.$nextTick();
                ul.scrollTop = scrollTop;
            }
        },
        reset() {
            this.search = null;
            this.$store.commit('receipt/SET_RECEIPT_REPORT', [])
            this.page = 1;
            this.limit = 10;
            this.limitData = this.limit;
        },
        resetFilters() {
            this.selectedCustomer = null;
            this.selectedCustomerGroup = null;
            this.selectedEmployee = null;
            this.selectedPaymentType = [];
            this.selectedStatus = [];
            this.selectedBank = [];
            this.receipt_date = '';
            this.receipt_validity = '';
            this.$store.commit("receipt/SET_RECEIPT_REPORT_QUERY", {});
        },
        downloadExcelFile() {
            let self = this;
            let data = self.receiptReportList.data.map(o => Object.fromEntries(self.selectedHeaders.map(k => [k.text, k.value.includes('.')?o[`${k.value.split('.')[0]}`] && Object.keys(o[`${k.value.split('.')[0]}`]).length > 0 ? o[`${k.value.split('.')[0]}`][`${k.value.split('.')[1]}`] : o[`${k.value.split('.')[0]}`]:o[`${k.value}`]])))
            data.forEach((el)=> {
            for (let x in el) {
              if (!el[x]) {
                el[x] = ''
              }}
            })
            exportFromJSON({data: JSON.parse(JSON.stringify(data)), fileName: "receiptReport", exportType: exportFromJSON.types.xls})
        },
        numberWithCommas(x) {
            return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (0).toFixed(2);
        },
        async paginate() {
            const {
                customer_id,
                customer_group_id,
                employee_id,
                selectedStatus,
                receipt_date,
                receipt_validity,
                selectedPaymentType,
                selectedBank,
            } = this.params;

            const payloadData = {
              type: "Receipt",
              where: customer_id ? "customer_id|" + customer_id : undefined,
              whereAnd: customer_group_id
                ? "group_id|" + customer_group_id
                : undefined,
              where_and: '',
              empty: customer_group_id ? 'group_id|' + 'NULL' : undefined,
              empty_1: selectedPaymentType ? "receipt_payments.payment_terms_id|" + 'NULL' : undefined
            }

            if (customer_group_id && customer_group_id === 111111111111111) {
              payloadData.empty, delete payloadData.whereAnd
            } else {
              payloadData.whereAnd, delete payloadData.empty
            }

            let where_and_prefix = ""
            let where_and_sufix = ""

            if (selectedPaymentType && selectedPaymentType !== 111111111111111) {
              where_and_prefix += "receipt_payments.payment_terms_id,",
              where_and_sufix += selectedPaymentType + ','
              delete payloadData.empty_1
            }

            if (selectedBank) {
              where_and_prefix += "receipt_payments.bank,",
              where_and_sufix += selectedBank + ','
            }

            if (employee_id) {
              where_and_prefix += "receipts.employee_id,",
              where_and_sufix += employee_id + ','
            }

            if (selectedStatus) {
              where_and_prefix += "status,",
              where_and_sufix += selectedStatus + ','
            }

            if (selectedStatus === 0) {
              where_and_prefix += "status,",
              where_and_sufix += 0 + ','
            }

            if (receipt_date) {
              where_and_prefix += "from_receipt_date,",
              where_and_sufix += receipt_date + ','
            }

            if (receipt_validity) {
              where_and_prefix += "to_receipt_date,",
              where_and_sufix += receipt_validity + ','
            }

            where_and_prefix = where_and_prefix.replace(/.$/,"|")
            where_and_sufix = where_and_sufix.replace(/.$/,"")

            payloadData.where_and = where_and_prefix + where_and_sufix

            this.isProgressCircular = true;
            await this.$store.dispatch("receipt/GetReceiptReport", payloadData).then(() => {
              this.isProgressCircular = false;
              this.$refs.search.focus();
            });
        },
    },
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>